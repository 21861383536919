var key = {}
if (process.env.NODE_ENV == "production") {
  // key = {
  //   projectId: "f2b118f1c1418730444fec61751c0f04",//wallet connect
  //   networkId: 56,
  //   contract: "0x37446d1d48779f3B6e797eE92816a70Fb87B2554",
  //   autoPoolcontract: "0x598792bf74Fb2BfE1a2469555b392eD2a27fdcfd",
  //   autoPoolcontractv2: "0x297B3c391617eF56760b25c57DCFaEd62A0e4a0a",
  //   tokenAddress: "0x55d398326f99059fF775485246999027B3197955",
  //   QbtAddress: "0xef048634a3583386d09ffad8b445c7c5a97ce179",
  //   frontUrl: "https://referrals.dezire.ai",
  //   serverUrl: "https://dezireuserapi.dezire.ai/api/user",
  //   txLink: "https://bscscan.com",
  //   rpcUrl: "https://bsc-dataseed1.ninicoin.io",
  //   exchangeUrl: "https://www.dezire.ai"
  // };

  key = {
    projectId: "f2b118f1c1418730444fec61751c0f04",//wallet connect
    networkId: 97,
    contract: "0xE987b7D4e14840089187b7F72FC1bf6cB63653C9",
    autoPoolcontract: "0x2EbBa935ffbE671E1A100DCC721eb22E3CaEC89F",
    autoPoolcontractv2: "0x67ab8e9C2fFB81Cd11F94509E62710252fE1EFC0",
    tokenAddress: "0xdAa36fab6996700FC5D3a13704CBc986e39119C8",
    QbtAddress: "0x8913D2D84C0B29b246d021384e966af79b448201",
    frontUrl: "http://localhost:3000",
    serverUrl: "https://dezireuserapi.wearedev.team/api/user",
    // serverUrl: "http://localhost:3001/api/user",
    txLink: "https://testnet.bscscan.com",
    rpcUrl: "https://bsc-testnet.publicnode.com",
    exchangeUrl: "https://deziredemo.wearedev.team"
  };

} else {
  // key = {
  //   // projectId: "f2b118f1c1418730444fec61751c0f04",//wallet connect
  //   // networkId: 56,
  //   // contract: "0x1917cb14Bb3751ea2f821653238b8dA4A68c0fC5",
  //   // tokenAddress: "0x55d398326f99059fF775485246999027B3197955",
  //   // frontUrl: "https://referrals.dezire.ai",
  //   // serverUrl: "https://dezireuserapi.dezire.ai/api/user",
  //   // txLink: "https://bscscan.com",
  //   // rpcUrl: "https://bsc-dataseed1.ninicoin.io",
  //   // exchangeUrl: "https://www.dezire.ai"
  //   projectId: "f2b118f1c1418730444fec61751c0f04",//wallet connect
  //   networkId: 11155111,
  //   contract: "0x25947E51A04C17f57d9FC6e336998a766C002c91",
  //   tokenAddress: "0xAca4d1B334700B5dc39F0c07dF7651D1be2D47A8",
  //   frontUrl: "http://localhost:3000",
  //   serverUrl: "http://localhost:3001/api/user",
  //   txLink: "https://sepolia.etherscan.io",
  //   rpcUrl: "https://sepolia.infura.io/v3/d5b89bd22ca242798dd9feb0ab031ccf",
  //   exchangeUrl: "https://deziredemo.wearedev.team"

  // };

  key = {
    projectId: "f2b118f1c1418730444fec61751c0f04",//wallet connect
    networkId: 97,
    contract: "0xE987b7D4e14840089187b7F72FC1bf6cB63653C9",
    autoPoolcontract: "0x2EbBa935ffbE671E1A100DCC721eb22E3CaEC89F",
    tokenAddress: "0xdAa36fab6996700FC5D3a13704CBc986e39119C8",
    autoPoolcontractv2: "0x67ab8e9C2fFB81Cd11F94509E62710252fE1EFC0",
    QbtAddress: "0x8913D2D84C0B29b246d021384e966af79b448201",
    frontUrl: "http://localhost:3000",
    serverUrl: "https://dezireuserapi.wearedev.team/api/user",
    // serverUrl: "http://localhost:3001/api/user",
    txLink: "https://testnet.bscscan.com",
    rpcUrl: "https://bsc-testnet.publicnode.com",
    exchangeUrl: "https://deziredemo.wearedev.team"
  };
  // key = {
  //   projectId: "f2b118f1c1418730444fec61751c0f04",//wallet connect
  //   networkId: 56,
  //   contract: "0x37446d1d48779f3B6e797eE92816a70Fb87B2554",
  //   autoPoolcontract: "0x9dBc848199c1195bAF2C0721d216fb865449560e",
  //   tokenAddress: "0x55d398326f99059fF775485246999027B3197955",
  //   frontUrl: "https://referrals.dezire.ai",
  //   serverUrl: "https://dezireuserapi.dezire.ai/api/user",
  //   txLink: "https://bscscan.com",
  //   rpcUrl: "https://bsc-dataseed1.ninicoin.io",
  //   exchangeUrl: "https://www.dezire.ai"
  // };
}
export default key;