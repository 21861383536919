import { Multicall } from "ethereum-multicall";

import config from "../../config/config";

import AUTOPOOLABI from "../../ABI/AUTOPOOLV2.json"

import {
    getFormatMulticall,
    formatNumber,
    getFormatMulticall1
} from "../../helper/custom";
import { connection } from "../../helper/connection";

import { convert } from "../../helper/convert";

export async function settingsInfo() {

    var get = await connection();
    var web3 = get.web3;
    var address = get.address;

    try {

        const multicall = new Multicall({
            web3Instance: web3,
        });

        var Contract = [
            {
                reference: "settings",
                contractAddress: config.autoPoolcontractv2,
                abi: AUTOPOOLABI,
                calls: [
                    {
                        reference: "tokenPrice",
                        methodName: "tokenPrice",
                        methodParameters: [],
                    },
                    {
                        reference: "claimFee",
                        methodName: "claimFee",
                        methodParameters: [],
                    },
                    {
                        reference: "owner",
                        methodName: "owner",
                        methodParameters: [],
                    },
                    {
                        reference: "isPause",
                        methodName: "isPause",
                        methodParameters: [],
                    },
                    {
                        reference: "minContractBalance",
                        methodName: "minContractBalance",
                        methodParameters: [],
                    },
                    {
                        reference: "childCount",
                        methodName: "childCount",
                        methodParameters: [],
                    },
                ]
            }
        ];

        const results = await multicall.call(Contract);

        var tokenPrice = await getFormatMulticall(results, "settings", 0);
        tokenPrice = (tokenPrice && tokenPrice.hex) ? parseInt(tokenPrice.hex) / 10 ** 6 : 0;
        tokenPrice = formatNumber(tokenPrice, 5);

        var claimFee = await getFormatMulticall(results, "settings", 1);
        claimFee = (claimFee && claimFee.hex) ? parseInt(claimFee.hex) / 10 ** 18 : 0;
        claimFee = formatNumber(claimFee, 5);

        var owner = await getFormatMulticall(results, "settings", 2);
        console.log(owner, 'ownerownerowner')
        owner = (owner) ? owner : "";

        var isPause = await getFormatMulticall(results, "settings", 3);

        var minContractBalance = await getFormatMulticall(results, "settings", 4);
        minContractBalance = (minContractBalance && minContractBalance.hex) ? parseInt(minContractBalance.hex) / 10 ** 18 : 0;
        minContractBalance = formatNumber(minContractBalance, 5);

        var childCount = await getFormatMulticall(results, "settings", 5);
        childCount = (childCount && childCount.hex) ? parseInt(childCount.hex) : 0;

        return {
            price: tokenPrice,
            claimFee,
            owner,
            address,
            isPause,
            minContractBalance,
            childCount
        }

    } catch (err) {
        console.log(err, 'settingsInfo errerr')
        return {
            price: 0,
            claimFee: 0,
            owner: "",
            address: "",
            isPause: true,
            minContractBalance: 0,
            childCount: 0
        }
    }

}

export async function updatePrice(price) {

    var get = await connection();
    var web3 = get.web3;
    var address = get.address;

    try {
        var price = parseFloat(price) * 10 ** 6;
        price = price.toString();
        price = convert(price);
        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontractv2);
        var getBalance = await web3.eth.getBalance(address);
        var balance = getBalance / 10 ** 18;
        if (balance === 0) {
            return {
                status: false,
                error: "Please make sure you have gas fee in your wallet."
            }
        }
        var gasPrice = await web3.eth.getGasPrice();
        var estimateGas = await Contract.methods.updateTokenPrice(
            price
        ).estimateGas({ from: address, gasPrice: gasPrice });
        var estimateGas = estimateGas + 100000;
        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                status: false,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }
        var result = await Contract.methods.updateTokenPrice(
            price
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        return {
            status: (result && result.status) ? result.status : false,
        }

    } catch (err) {
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var pos2 = errMsg.search("gas required exceeds");
        var errorMsg = "Failed to update"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0 || pos2 >= 0) {
            errorMsg = "Sorry unable to proceed, Please make sure you have enough gas fee!";
        }
        return {
            status: (result && result.status) ? result.status : false,
            error: errorMsg,
        }
    }
}



export async function updateClaimFee(price) {

    var get = await connection();
    var web3 = get.web3;
    var address = get.address;

    try {
        var price = parseFloat(price) * 10 ** 18;
        price = price.toString();
        price = convert(price);
        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontractv2);
        var getBalance = await web3.eth.getBalance(address);
        var balance = getBalance / 10 ** 18;
        if (balance === 0) {
            return {
                status: false,
                error: "Please make sure you have gas fee in your wallet."
            }
        }
        var gasPrice = await web3.eth.getGasPrice();
        var estimateGas = await Contract.methods.updateClaimFee(
            price
        ).estimateGas({ from: address, gasPrice: gasPrice });

        var estimateGas = estimateGas + 100000;
        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                status: false,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }
        var result = await Contract.methods.updateClaimFee(
            price
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        return {
            status: (result && result.status) ? result.status : false
        }

    } catch (err) {
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var pos2 = errMsg.search("gas required exceeds");
        var errorMsg = "Failed to update"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0 || pos2 >= 0) {
            errorMsg = "Sorry unable to proceed, Please make sure you have enough gas fee!";
        }
        return {
            status: (result && result.status) ? result.status : false,
            error: errorMsg,
        }
    }
}


export async function updateMinBalance(price) {

    var get = await connection();
    var web3 = get.web3;
    var address = get.address;

    try {
        var price = parseFloat(price) * 10 ** 18;
        price = price.toString();
        price = convert(price);
        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontractv2);
        var getBalance = await web3.eth.getBalance(address);
        var balance = getBalance / 10 ** 18;
        if (balance === 0) {
            return {
                status: false,
                error: "Please make sure you have gas fee in your wallet."
            }
        }
        var gasPrice = await web3.eth.getGasPrice();
        var estimateGas = await Contract.methods.updateMinContractBalance(
            price
        ).estimateGas({ from: address, gasPrice: gasPrice });

        var estimateGas = estimateGas + 100000;
        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                status: false,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }
        var result = await Contract.methods.updateMinContractBalance(
            price
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        return {
            status: (result && result.status) ? result.status : false
        }

    } catch (err) {
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var pos2 = errMsg.search("gas required exceeds");
        var errorMsg = "Failed to update"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0 || pos2 >= 0) {
            errorMsg = "Sorry unable to proceed, Please make sure you have enough gas fee!";
        }
        return {
            status: (result && result.status) ? result.status : false,
            error: errorMsg,
        }
    }

}

export async function updateChild(child) {

    var get = await connection();
    var web3 = get.web3;
    var address = get.address;

    try {
        // var price = parseFloat(price) * 10 ** 18;
        child = child.toString();
        //child = convert(child);
        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontractv2);
        var getBalance = await web3.eth.getBalance(address);
        var balance = getBalance / 10 ** 18;
        if (balance === 0) {
            return {
                status: false,
                error: "Please make sure you have gas fee in your wallet."
            }
        }
        var gasPrice = await web3.eth.getGasPrice();
        var estimateGas = await Contract.methods.updatePayoutratio(
            child
        ).estimateGas({ from: address, gasPrice: gasPrice });

        var estimateGas = estimateGas + 100000;
        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                status: false,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }
        var result = await Contract.methods.updatePayoutratio(
            child
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        return {
            status: (result && result.status) ? result.status : false
        }

    } catch (err) {
        console.log(err, 'errerrerrerr')
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var pos2 = errMsg.search("gas required exceeds");
        var errorMsg = "Failed to update"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0 || pos2 >= 0) {
            errorMsg = "Sorry unable to proceed, Please make sure you have enough gas fee!";
        }
        return {
            status: (result && result.status) ? result.status : false,
            error: errorMsg,
        }
    }

}

export async function updateIsPause(status) {

    var get = await connection();
    var web3 = get.web3;
    var address = get.address;

    try {

        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontractv2);
        var getBalance = await web3.eth.getBalance(address);
        var balance = getBalance / 10 ** 18;
        if (balance === 0) {
            return {
                status: false,
                error: "Please make sure you have gas fee in your wallet."
            }
        }
        var gasPrice = await web3.eth.getGasPrice();
        var estimateGas = await Contract.methods.updateIspause(status
        ).estimateGas({ from: address, gasPrice: gasPrice });

        var estimateGas = estimateGas + 100000;
        if (parseFloat(estimateGas) / 10 ** 8 > balance) {
            return {
                status: false,
                error: `Please make sure you have gas fee(${parseFloat(estimateGas) / 10 ** 8} BNB) in your wallet.`
            }
        }
        var result = await Contract.methods.updateIspause(status
        ).send({ from: address, gasLimit: estimateGas, gasPrice: gasPrice });

        return {
            status: (result && result.status) ? result.status : false
        }

    } catch (err) {
        var errMsg = (err && err.message) ? err.message.toString() : "";
        var pos = errMsg.search("User denied");
        var pos1 = errMsg.search("funds");
        var pos2 = errMsg.search("gas required exceeds");
        var errorMsg = "Failed to update"
        if (pos >= 0) {
            errorMsg = "Cancelled";
        } else if (pos1 >= 0 || pos2 >= 0) {
            errorMsg = "Sorry unable to proceed, Please make sure you have enough gas fee!";
        }
        return {
            status: (result && result.status) ? result.status : false,
            error: errorMsg,
        }
    }

}
var claimed = 0;
var unclaimed = 0;
var start = 1;
var end = 100;
var stopLoop = false;
export async function checkUSDT(setunclaimed, setclaimed, setisDisable5) {

    if (stopLoop) {
        claimed = 0;
        unclaimed = 0;
        start = 1;
        end = 100;
        stopLoop = false;
    }

    var get = await connection();
    var web3 = get.web3;
    var address = get.address;

    try {

        var Contract = new web3.eth.Contract(AUTOPOOLABI, config.autoPoolcontractv2);
        var totalUsers = await Contract.methods.totalUsers().call();

        const multicall = new Multicall({
            web3Instance: web3,
        });
        var Contract = [];
        for (var r = start; r <= end; r++) {
            //console.log(r, 'startstart')
            Contract.push({
                reference: "userList-" + r,
                contractAddress: config.autoPoolcontractv2,
                abi: AUTOPOOLABI,
                calls: [
                    {
                        reference: "userList",
                        methodName: "userList",
                        methodParameters: [r],
                    }

                ]
            });
        }
        const results = await multicall.call(Contract);
        var addressList = [];
        var Contract1 = [];
        for (var r1 = start; r1 <= end; r1++) {
            var address = await getFormatMulticall1(results, "userList-" + r1, 0);
            // addressList.push(address[0]);
            // console.log(address[0], 'address[0]')
            if (address[0] !== "0x0000000000000000000000000000000000000000") {
                Contract1.push({
                    reference: "users-" + r1,
                    contractAddress: config.autoPoolcontractv2,
                    abi: AUTOPOOLABI,
                    calls: [
                        {
                            reference: "users",
                            methodName: "users",
                            methodParameters: [address[0]],
                        }

                    ]
                });
            }
            if (address[0] == "0x0000000000000000000000000000000000000000") {
                stopLoop = true;
            }


        }
        if (Contract1 && Contract1.length > 0) {

            const results1 = await multicall.call(Contract1);

            for (var r2 = start; r2 <= end; r2++) {
                var userInfo = await getFormatMulticall1(results1, "users-" + r2, 0);
                var claimable = (userInfo && userInfo[6] && userInfo[6].hex) ? parseInt(userInfo[6].hex) / 10 ** 18 : 0;
                var totalClaimed = (userInfo && userInfo[8] && userInfo[8].hex) ? parseInt(userInfo[8].hex) / 10 ** 18 : 0;
                var id = (userInfo && userInfo[1] && userInfo[1].hex) ? parseInt(userInfo[1].hex) : 0;
                console.log(claimable, "userInfouserInfouserInfo", totalClaimed, id)

                unclaimed += claimable;
                claimed += totalClaimed;


            }

        }


        console.log(unclaimed, 'unclaimed', claimed, start, end, stopLoop)
        if (stopLoop) {
            setunclaimed(unclaimed);
            setclaimed(claimed);
            setisDisable5(false)
        } else {
            if (end <= totalUsers) {
                start = parseInt(start) + 100;
                end = parseInt(end) + 100;
                setTimeout(function () {
                    checkUSDT(setunclaimed, setclaimed, setisDisable5)
                }, 500)
            }

        }


    } catch (err) {
        console.log(err, 'checkUSDTerrerrerr')
    }

}
