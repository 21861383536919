import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactLoading from "react-loading";

import Footer from "../../components/Footer.js";
import Navbar from "../../components/Navbar.js";

import {
  getAutoPoolInfo,
  getInitialautopool,
  getJoinInfos,
  getInitialJoinInfos
} from "./autopoolinfo.js";

import ReJoinModalv2 from "./reJoinModalv2.js";
import ClaimModalv2 from "./claimModalv2.js"
import JoinModalv2 from "./joinModalv2.js";

import { getAutoPoolUsers } from "../../Api/Actions.js";

var userVal = [
  {
    id: 1,
    createdAt: new Date()
  },
  {
    id: 6,
    createdAt: new Date()
  },
  {
    id: 12,
    createdAt: new Date()
  }
]

export default function Autopoolv2() {

  const navigate = useNavigate();

  const walletConnection = useSelector((state) => state.walletConnection);

  const [autoPoolInfo, setautoPoolInfo] = useState(getInitialautopool());
  const [userPoolInfo1, setuserPoolInfo1] = useState(getInitialJoinInfos());
  const [isLoad, setisLoad] = useState(false);
  const [joinText, setjoinText] = useState("");
  const [userList, setuserList] = useState(userVal);
  const [skip, setskip] = useState(0);

  useEffect(() => {
    loadInitial();
  }, [walletConnection]);

  async function loadInitial() {
    setisLoad(true);
    if (walletConnection && walletConnection.address !== "") {
      var resp = await getAutoPoolInfo();

      setautoPoolInfo(resp);
      if (resp.isExistAutoPool1) {
        if (!resp.isJoined || resp.isJoined == false) {
          window.$("#join_modal").modal('show');
        }
      } else {
        navigate("/join-now");
      }

      var resp1 = await getJoinInfos(resp.userIds);
      setuserPoolInfo1(resp1)
      console.log(resp1, 'resp1resp1')
      //setuserList(result)
    }
    setisLoad(false);
  }

  function reload1() {
    loadInitial();
  }


  function setText(val) {
    setjoinText();
    if (val == "join") {
      setjoinText("Join");
    } else {
      setjoinText("Re Join");
    }
  }

  return (
    <>
      <Navbar />
      <div className="dashboard">
        <div className="container">
          {/* <h6 className="mb-4 pb-2" >Referral</h6> */}
          <div className="head_tab mb-4">
            <Link to="/dashboard" className="primary_btn " >Referral Program</Link>
            <Link to="/#" className="primary_btn" data-bs-toggle="modal" data-bs-target="#autopool_coming_soon">Autopool1.0</Link>
            <Link to="/#" className="primary_btn  active" data-bs-toggle="modal" data-bs-target="#autopool_coming_soon">Autopool2.0</Link>
          </div>

          <div className="row">
            <div className="col-lg-4 col-xl-3">
              <div className="box">
                <div>
                  <h5>{userPoolInfo1.id}</h5>
                  <p>ID</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d1.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{userPoolInfo1.rebirthid}</h5>
                  <p>Rebirth ID</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/topupuser.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  {autoPoolInfo.isJoined ?
                    <h5>{(userPoolInfo1.isReach) ? "InActive" : "Active"}</h5>
                    :
                    <h5>InActive</h5>
                  }
                  <p className="text-start" >Status</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/activeuser.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{userPoolInfo1.childCount}</h5>
                  <p>Child Count</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/d2.png")} alt="img" className="img-fluid" />
                </div>
              </div>
              <div className="box">
                <div>
                  <h5>{autoPoolInfo.retopupCount}</h5>
                  <p>Retopup count</p>
                </div>
                <div className="icon">
                  <img src={require("../../assets/images/topupcnt.png")} alt="img" className="img-fluid" />
                </div>
              </div>

            </div>

            <div className="col-lg-8 col-xl-9">
              <div className="bdr mb-4">
                <div className="row top">
                  <div className="col-lg-4 d-flex">
                    <div className="box">
                      <p> Total investment </p>
                      <h6>{autoPoolInfo.totalInvest} USDT</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="box">
                      <p>Total Claimed</p>
                      <h6>{autoPoolInfo.totalClaimed} USDT</h6>
                    </div>
                  </div>
                  <div className="col-lg-4 d-flex">
                    <div className="box">
                      <p>Total Child</p>
                      <h6>{userPoolInfo1.totalChild}</h6>
                    </div>
                  </div>
                </div>
                {isLoad && (
                  <div className="text-center dashboardloader">
                    <ReactLoading type={"bars"} color="#efcb46" className="loading" />
                  </div>
                )}
                <div className="box amount">
                  <div className="flx">
                    <p className="me-2 mt-0"> Claimable Amount :</p>
                    <h6>{autoPoolInfo.earnedamount} USDT</h6>
                  </div>
                  <div className="flx gap-3">

                    <button
                      className={(parseFloat(autoPoolInfo.earnedamount) > 0) ? " primary_btn m-0" : " primary_btn m-0 disabledbtn"}
                      data-bs-toggle="modal"
                      data-bs-target="#autopool_claim_modal"
                      disabled={(parseFloat(autoPoolInfo.earnedamount) > 0) ? false : true}
                    >Claim</button>

                    {/* <button
                      className="primary_btn m-0"
                      data-bs-toggle="modal"
                      data-bs-target="#advancepay_modal"
                      disabled={autoPoolInfo.isJoined ? false : true}
                    >Topup</button> */}
                    {autoPoolInfo.isJoined ?
                      <button
                        className={(userPoolInfo1.isReach) ? " primary_btn m-0" : " primary_btn m-0 disabledbtn"}
                        data-bs-toggle="modal"
                        data-bs-target="#rejoin_modal"
                        disabled={userPoolInfo1.isReach ? false : true}
                      >Re-Topup</button>
                      :
                      <button
                        className="primary_btn m-0"
                        data-bs-toggle="modal"
                        data-bs-target="#join_modal"
                        onClick={() => setText('join')}
                      >Join</button>
                    }
                  </div>
                </div>
              </div>
              <div className="bdr mb-4" >
                <div class="scroll">
                  <h6 class="mb-3">Join History</h6>
                  <div class="table-responsive">
                    <table class="table table-borderless align-middle">
                      <thead class="table-light">
                        <tr>
                          <th>S.no</th>
                          <th>ID</th>
                          <th>Join Amount</th>
                          <th>Date. Of. Joining</th>
                        </tr>
                      </thead>
                      <tbody class="table-group-divider">
                        {userPoolInfo1 && userPoolInfo1.result &&
                          userPoolInfo1.result.length > 0 &&
                          userPoolInfo1.result.map((item, index1) => {
                            return (<tr>
                              <td>{index1 + 1}</td>
                              <td>{item.id}</td>
                              <td>15 USDT</td>
                              <td>{moment(item.joinedTime).format("MMMM,Do YYYY hh:mm")}</td>
                            </tr>);
                          })}
                      </tbody>
                    </table>
                    {userPoolInfo1 && userPoolInfo1.result.length === 0 && <div className="text-center" >No records</div>}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade primary_modal success"
            id="autopool_coming_soon"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header text-center">
                  <img src={require("../../assets/images/auto-pool-coing-soon.png")} alt="img" className="img-fluid mx-auto" />
                  <button
                    type="button"
                    className="btn-close absolute-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <ReJoinModalv2
            reload={reload1}
            joinText={joinText}
            walletConnection={walletConnection}
            joinAmount={autoPoolInfo.joinAmount}
          />
          <ClaimModalv2
            reload={reload1}
            walletConnection={walletConnection}
          />
          <JoinModalv2
            reload={reload1}
            walletConnection={walletConnection}
            joinAmount={autoPoolInfo.joinAmount}
          />
        </div>
      </div>
      <Footer />
    </>
  );
}
